import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';

import { publish, subscribe } from 'javascripts/utils/events';

// ---- setup vault sales swiper when modal opens ------------------------------------------------------------------- //

// configure Swiper to use modules
SwiperCore.use([Pagination]);

let announcementSwiper: SwiperCore;

subscribe('MODAL_OPENED', (id) => {
  if (id !== 'redesign-announcement') return;

  if (announcementSwiper) announcementSwiper.slideTo(0);

  const swiperRoot = document.querySelector('.redesign-announcement-swiper');
  if (swiperRoot && !announcementSwiper) announcementSwiper = new SwiperCore(swiperRoot as HTMLElement, {
    spaceBetween: 16,
    centeredSlides: true,
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
});
